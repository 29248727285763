import React from 'react';
import i18n from 'utils/i18n';
import { PageProvider } from 'providers/page';
import PageMetaContent from 'components/atoms/page-meta-content';
import Header from 'components/organisms/header';

import Error404 from 'components/molecules/error-404';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
import css from './styles.module.scss';
import Menu from 'utils/repo/menu';

function NotFound(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('pages.404');

  return (
    <div className={css['page__404-container']}>
      <Error404 />
    </div>
  );
}

export default function NotFoundPage(props) {
  const { children, ...other } = props;
  return (
    <>
      <PageMetaContent {...props} is404={true} />
      <Header menuData={props?.menuData || []} fullpath={props?.fullpath} />
      <PageProvider>
        <NotFound {...other} />
        {children}
      </PageProvider>
    </>
  );
}

export async function getStaticProps(context) {
  const productId = serverRuntimeConfig?.PRODUCT_ID || '';
  const { locale } = context;

  const menuData = await Menu.getMenuItemsByType({ type: 'main', productId });
  const fullPath = '/';

  const title = 'Home | Toyota';
  const description = 'Home Page';
  const slug = '/';

  let msg = {};

  try {
    msg = await i18n.getMessages(locale);
  } catch (err) {
    console.error('Error while getting messages on 404 page', err);
  }

  return {
    props: {
      messages: msg,
      fullpath: fullPath,
      title: title,
      description: description,
      slug: slug,
      tenant: locale,
      menuData
    }
  };
}
