import Title from 'components/atoms/title';
import AnchorLink from 'components/atoms/anchor-link';
import Media from 'components/molecules/media';
import React from 'react';

import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Error404
 *
 * Error 404
 */
function Error404(props) {
  const { className = '', children, ...other } = props;
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('pages.404');

  return (
    <>
      <div className={`${css['molecule__error-404-container']} ${className}`} {...other}>
        <div className={css['media-container']}>
          <Media url="/images/404-banner.jpg" type="img" />
        </div>

        <div className={css['content-container']}>
          <div className={css['title-container']}>
            <Title level={1} variant={2}>
              {t('404-title')}
            </Title>
          </div>

          <p className={css['description']}>{t('404-content')}</p>

          <div className={css['buttons-container']}>
            <AnchorLink link="/" linkLabel={t('404-back-to-home')} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Error404;
